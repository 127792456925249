export const people = [
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
  {
    firstname: 'Tifanny',
    lastname: 'Bemuah',
    email: 'tifanny.one@login.com',
    referrals: '34',
    commission: '$567',
  },
]
