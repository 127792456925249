import React from 'react'
import SectionItem from '../../../../components/sectionitem/edit'
import CreateUpdateModal from '../../../../components/modals/create-update-modal'

const RequestEditImagingInfo = () => {
  const [open, setOpen] = React.useState<boolean>(false)
  return (
    <>
      {open && <CreateUpdateModal type={'Broker'} updated setOpen={setOpen} />}
      <div>
        <div className="divide divide-y divide-gray-200 min-w-min">
          <SectionItem title={'#'} value={'101'} />
          <SectionItem title={'Requested for'} value={'Leslie Alexander'} />
          <SectionItem title={'Requested by'} value={'Wade Warren'} />
          <SectionItem title={'Date requested'} value={''} />
          <SectionItem inputType={'textarea'} title={'Notes'} value={''} />
        </div>
        <div className="flex">
          <div className="w-1/4" />
          <div className="space-x-5">
            <button
              onClick={() => setOpen(true)}
              className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light"
            >
              Save Changes
            </button>

            <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
              Discard
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestEditImagingInfo
