export const people = [
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '1',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '2',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '3',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '4',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '1',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '2',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '3',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '4',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '1',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '2',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '3',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '4',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '1',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '2',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '3',
    dateRequested: '16-08-2021',
  },
  {
    id: '101',
    requestFor: 'Leslie Alexander',
    requestedBy: {
      name: 'Wade Warren',
      image:
        'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80',
    },
    requestStatus: '4',
    dateRequested: '16-08-2021',
  },
]
