import React from 'react'
import SectionItem from '../../../components/sectionitem/view'
import Avatar from '../../../assets/images/avatar.png'

const UserProviders = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem
        title={'Remaining visits'}
        value={
          <div>
            <div className="flex-1 flex items-center space-x-3">
              <div className="w-10 h-10 overflow-hidden rounded-full">
                <img
                  className="w-full h-full object-cover object-center"
                  src={Avatar}
                  alt="avatar image"
                />
              </div>
              <div className="font-normal text-sm">Morrison Floyd</div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default UserProviders
