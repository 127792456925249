import React from 'react'
import SectionItem from '../../../../components/sectionitem/create'
import CreateUpdateModal from '../../../../components/modals/create-update-modal'

const RequestImagingCreateData = () => {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      {open && <CreateUpdateModal setOpen={setOpen} created type={'Broker'} />}

      <div>
        <div className="divide divide-y divide-gray-200">
          <SectionItem title={'#'} value={'101'} />
          <SectionItem title={'Requested for'} value={''} />
          <SectionItem title={'Requested by'} value={''} />
          <SectionItem title={'State'} value={''} />
          <SectionItem
            title={'Request status'}
            value={
              <select
                name=""
                id=""
                className="rounded-lg border border-gray-200 text-sm outline-none py-2 px-4 w-2/5 appearance-none"
              >
                <option value="">Select status</option>
              </select>
            }
          />
          <SectionItem title={'Date requested'} value={''} />
          <SectionItem inputType={'textarea'} title={'Notes'} value={''} />
        </div>

        <div className="flex">
          <div className="w-1/4" />
          <div className="space-x-5">
            <button
              onClick={() => setOpen(true)}
              className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light"
            >
              Save Changes
            </button>

            <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
              Discard
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestImagingCreateData
