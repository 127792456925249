import React from 'react'
import RequestEditImagingInfo from './info'
import RequestEditImagingData from './data'
import BackButton from '../../../../components/buttons/back-button'
import Tabs from '../../../../components/tabs'

const pageTabs = [
  { sort: 1, name: 'Request info', current: false, component: <RequestEditImagingInfo /> },
  { sort: 2, name: 'Request data', current: false, component: <RequestEditImagingData /> },
]

const RequestLabEdit = () => {
  return (
    <div className="space-y-8 h-full flex flex-col">
      <BackButton />
      <Tabs tabs={pageTabs} />
    </div>
  )
}

export default RequestLabEdit
