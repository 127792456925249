import React from 'react'
import SectionItem from '../../../../components/sectionitem/edit'
import CreateUpdateModal from '../../../../components/modals/create-update-modal'

const RequestEditLabData = () => {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      {open && <CreateUpdateModal type={'Broker'} updated setOpen={setOpen} />}
      <div>
        <div className="divide divide-y divide-gray-200">
          <SectionItem title={'Name'} value={'Leslie Alexander '} />
          <SectionItem title={'D.O.B'} value={'16-08-2021'} />
          <SectionItem title={'Email'} value={'lesliealex@gmail.com'} />
          <SectionItem title={'Zip code'} value={'12097'} />
        </div>

        <div className="flex">
          <div className="w-1/4" />
          <div className="space-x-5">
            <button
              onClick={() => setOpen(true)}
              className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light"
            >
              Save Changes
            </button>

            <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
              Discard
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestEditLabData
