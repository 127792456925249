import React from 'react'
import SectionItem from '../../../components/sectionitem/view'

const BrokerViewReferralsAndCommissions = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem title={'Total referrals'} value={'34'} />
      <SectionItem title={'Commissions this month'} value={'$546'} />
      <SectionItem title={'Lifetime Commission'} value={'$910'} />
    </div>
  )
}

export default BrokerViewReferralsAndCommissions
