import React from 'react'
import SectionItem from '../../../components/sectionitem/view'

const BrokerViewGeneral = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem title={'First name'} value={'Tifanny'} />
      <SectionItem title={'Last name'} value={'Bemuah'} />
      <SectionItem title={'Email'} value={'tifanny.done@login.com'} />
      <SectionItem title={'Phone'} value={'+123456789112'} />
      <SectionItem title={'Password'} value={'* * * * * *'} />
      <SectionItem title={'Description'} value={''} />
    </div>
  )
}

export default BrokerViewGeneral
