import React from 'react'
import { useNavigate } from 'react-router-dom'

const NewButton = () => {
  const navigate = useNavigate()

  return (
    <button
      onClick={() => navigate('create')}
      className="bg-riverBlue rounded-lg text-white flex items-center px-4 space-x-2"
    >
      <span className="inline-block">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-5 w-5"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth={2}
            d="M12 6v6m0 0v6m0-6h6m-6 0H6"
          />
        </svg>
      </span>
      <span className="inline-block text-sm font-normal">New User</span>
    </button>
  )
}

export default NewButton
