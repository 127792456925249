export const people = [
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: false,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: false,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
  {
    firstname: 'Tifanny',
    lastname: 'One',
    email: 'tifanny.one@login.com',
    phone: '+12135755201',
    dob: '1995-11-04',
    subscription: true,
  },
]
