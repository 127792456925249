import React from 'react'
import Tabs from '../../../components/tabs'
import UserCard from '../../../components/usercard'
import BrokerEditGeneral from './general'
import BrokerEditAddress from './address'
import BrokerEditReferralsAndCommissions from './referrals'
import BackButton from '../../../components/buttons/back-button'

const pageTabs = [
  { sort: 1, name: 'General', current: false, component: <BrokerEditGeneral /> },
  { sort: 2, name: 'Address', current: false, component: <BrokerEditAddress /> },
  {
    sort: 3,
    name: 'Referrals & Commissions',
    current: false,
    component: <BrokerEditReferralsAndCommissions />,
  },
]

const BrokerEdit = () => {
  return (
    <div className="space-y-8 h-full flex flex-col">
      <BackButton />

      <UserCard />
      <Tabs tabs={pageTabs} />
    </div>
  )
}

export default BrokerEdit
