import React from 'react'
import { useNavigate } from 'react-router-dom'

const BackButton = () => {
  const navigate = useNavigate()

  return (
    <div className="flex justify-end">
      <button onClick={() => navigate(-1)} className="back-button">
        <span className="back-button__icon">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M11 17l-5-5m0 0l5-5m-5 5h12"
            />
          </svg>
        </span>
        <span className="inline-block text-sm font-light font-medium">Back to list</span>
      </button>
    </div>
  )
}

export default BackButton
