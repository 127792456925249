import React from 'react'
import SectionItem from '../../../components/sectionitem/create'

const UserCreateProviders = () => {
  return (
    <div>
      <div className="divide divide-y divide-gray-200">
        <SectionItem
          title={'Assign provider'}
          value={
            <div className="flex-1 flex  space-x-5">
              <input
                type="text"
                className={
                  'rounded-lg border border-gray-200 text-sm outline-none py-2 px-4 min-w-[40%]'
                }
                defaultValue={''}
              />

              <button
                onClick={() => console.log('create')}
                className="bg-orange-100 rounded-lg text-gray-800 flex items-center px-4 space-x-2"
              >
                <span className="inline-block">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth={2}
                      d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                    />
                  </svg>
                </span>
                <span className="inline-block text-sm font-normal ">Add User</span>
              </button>
            </div>
          }
        />
      </div>

      <div className="flex">
        <div className="w-1/4" />
        <div className="space-x-5">
          <button className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light">
            Save Changes
          </button>

          <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
            Discard
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserCreateProviders
