import React, { ReactNode } from 'react'

interface Props {
  time: string
  date: string
  title: string
  icon: ReactNode
}

const NotificationItem = (props: Props) => {
  return (
    <div className="py-3 flex space-x-3 items-center cursor-pointer hover:bg-gray-50 px-3">
      <div className="bg-riverStatsLightOrange w-10 h-10 flex items-center justify-center rounded-full">
        {props.icon}
      </div>
      <div className="flex-1 space-y-1">
        <div className="flex justify-between">
          <div className="text-[10px] text-gray-400">{props.time}</div>
          <div className="text-[10px] text-gray-400">{props.date}</div>
        </div>
        <div className="text-xs font-medium">{props.title}</div>
      </div>
    </div>
  )
}

export default NotificationItem
