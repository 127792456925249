import React from 'react';
import SideBar from "../sidebar";
import Main from "../main";

const Layout = () => {
  return (
    <div className="admin_container">
      <SideBar />
      <Main />
    </div>
  );
};

export default Layout;