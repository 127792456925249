import React from 'react'

interface Props {
  buttonEvent(): void
}

const EditButton = (props: Props) => {
  return (
    <button
      className="bg-gray-200 rounded-lg flex items-center px-5 py-2 space-x-3 border"
      onClick={props.buttonEvent}
    >
      <svg className="w-4 h-4" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          opacity="0.4"
          d="M14.6777 14.6665H2.67773C2.4044 14.6665 2.17773 14.4398 2.17773 14.1665C2.17773 13.8932 2.4044 13.6665 2.67773 13.6665H14.6777C14.9511 13.6665 15.1777 13.8932 15.1777 14.1665C15.1777 14.4398 14.9511 14.6665 14.6777 14.6665Z"
          fill="#7B8A93"
        />
        <path
          opacity="0.4"
          d="M13.3576 2.31994C12.0643 1.02661 10.7976 0.993275 9.47095 2.31994L8.66429 3.12661C8.59762 3.19328 8.57095 3.29994 8.59762 3.39328C9.10429 5.15994 10.5176 6.57328 12.2843 7.07994C12.311 7.08661 12.3376 7.09328 12.3643 7.09328C12.4376 7.09328 12.5043 7.06661 12.5576 7.01328L13.3576 6.20661C14.0176 5.55328 14.3376 4.91994 14.3376 4.27994C14.3443 3.61994 14.0243 2.97994 13.3576 2.31994Z"
          fill="#7B8A93"
        />
        <path
          d="M11.0843 7.68654C10.891 7.5932 10.7043 7.49987 10.5243 7.3932C10.3776 7.30654 10.2376 7.2132 10.0976 7.1132C9.98429 7.03987 9.85095 6.9332 9.72429 6.82654C9.71095 6.81987 9.66429 6.77987 9.61095 6.72654C9.39095 6.53987 9.14429 6.29987 8.92429 6.0332C8.90429 6.01987 8.87095 5.9732 8.82429 5.9132C8.75762 5.8332 8.64429 5.69987 8.54429 5.54654C8.46429 5.44654 8.37095 5.29987 8.28429 5.1532C8.17762 4.9732 8.08429 4.7932 7.99095 4.60654C7.89762 4.40654 7.82429 4.2132 7.75762 4.0332L3.57095 8.21987C3.48429 8.30654 3.40429 8.4732 3.38429 8.58654L3.02429 11.1399C2.95762 11.5932 3.08429 12.0199 3.36429 12.3065C3.60429 12.5399 3.93762 12.6665 4.29762 12.6665C4.37762 12.6665 4.45762 12.6599 4.53762 12.6465L7.09762 12.2865C7.21762 12.2665 7.38429 12.1865 7.46429 12.0999L11.651 7.9132C11.4643 7.84654 11.2843 7.7732 11.0843 7.68654Z"
          fill="#7B8A93"
        />
      </svg>

      <span className="inline-block text-sm text-gray-600 font-normal">Edit</span>
    </button>
  )
}

export default EditButton
