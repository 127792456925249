import React from 'react'
import SectionItem from '../../../components/sectionitem/create'

const UserCreateGeneral = () => {
  return (
    <div>
      <div className=" min-w-min">
        <SectionItem title={'First name'} value={''} />
        <SectionItem title={'Last name'} value={''} />
        <SectionItem title={'Email'} value={''} />
        <SectionItem title={'Phone'} value={''} />
        <SectionItem title={'Password'} value={''} />
        <SectionItem inputType={'textarea'} title={'Description'} value={''} />
      </div>
      <div className="flex">
        <div className="w-1/4" />
        <div className="space-x-5">
          <button className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light">
            Save Changes
          </button>

          <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
            Discard
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserCreateGeneral
