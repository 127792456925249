import React from 'react'
import BrokerIndex from './broker/index'
import Overview from './broker/overview'
import BrokerView from './broker/view'
import BrokerEdit from './broker/edit'
import BrokerCreate from './broker/create'

import EmployerIndex from './employers/index'
import EmployerOverview from './employers/overview'
import EmployerView from './employers/view/index'

import UserIndex from './users/index'
import UsersOverview from './users/overview'
import UsersView from './users/view'
import UserEdit from './users/edit'
import UserCreate from './users/create'

import RequestLabIndex from './request/lab/index'
import RequestLabOverview from './request/lab/overview'
import RequestLabView from './request/lab/view/index'
import RequestLabEdit from './request/lab/edit/index'
import RequestLabCreate from './request/lab/create/index'

import RequestImagingIndex from './request/imaging/index'
import RequestImagingOverview from './request/imaging/overview'
import RequestImagingView from './request/imaging/view/index'
import RequestImagingEdit from './request/imaging/edit'
import RequestImagingCreate from './request/imaging/create/index'

export interface IRoutes {
  title: string
  link: string
  component?: React.ReactNode
  children?: IRoutes[]
}

export const routes: IRoutes[] = [
  {
    title: 'users',
    link: 'users',
    component: <UserIndex />,
    children: [
      {
        title: 'overview',
        link: '',
        component: <UsersOverview />,
      },
      {
        title: 'about',
        link: 'view',
        component: <UsersView />,
      },
      {
        title: 'edit',
        link: 'edit',
        component: <UserEdit />,
      },
      {
        title: 'create',
        link: 'create',
        component: <UserCreate />,
      },
    ],
  },
  {
    title: 'broker',
    link: 'broker',
    component: <BrokerIndex />,
    children: [
      {
        title: 'overview',
        link: '',
        component: <Overview />,
      },
      {
        title: 'about',
        link: 'view',
        component: <BrokerView />,
      },
      {
        title: 'edit',
        link: 'edit',
        component: <BrokerEdit />,
      },
      {
        title: 'create',
        link: 'create',
        component: <BrokerCreate />,
      },
    ],
  },
  {
    title: 'employers',
    link: 'employers',
    component: <EmployerIndex />,
    children: [
      {
        title: 'overview',
        link: '',
        component: <EmployerOverview />,
      },
      {
        title: 'overview',
        link: 'view',
        component: <EmployerView />,
      },
    ],
  },
  {
    title: 'request-lab',
    link: 'request/lab',
    component: <RequestLabIndex />,
    children: [
      {
        title: 'overview',
        link: '',
        component: <RequestLabOverview />,
      },
      {
        title: 'view',
        link: 'view',
        component: <RequestLabView />,
      },
      {
        title: 'edit',
        link: 'edit',
        component: <RequestLabEdit />,
      },
      {
        title: 'create',
        link: 'create',
        component: <RequestLabCreate />,
      },
    ],
  },
  {
    title: 'request-imaging',
    link: 'request/imaging',
    component: <RequestImagingIndex />,
    children: [
      {
        title: 'overview',
        link: '',
        component: <RequestImagingOverview />,
      },
      {
        title: 'view',
        link: 'view',
        component: <RequestImagingView />,
      },
      {
        title: 'edit',
        link: 'edit',
        component: <RequestImagingEdit />,
      },
      {
        title: 'create',
        link: 'create',
        component: <RequestImagingCreate />,
      },
    ],
  },
]
