import React from 'react'
import SectionItem from '../../../components/sectionitem/view'

const UserRoles = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem
        title={'Select roles'}
        value={
          <div className="w-1/4">
            <div className="flex-1 flex flex-col space-y-5">
              <div className="flex border w-full rounded-md py-2 px-3 justify-between">
                <div className="text-sm text-gray-800">Admin</div>
                <div>
                  <input className="rounded-md" type="checkbox" />
                </div>
              </div>
              <div className="flex border w-full rounded-md py-2 px-3 justify-between">
                <div className="text-sm text-gray-800">Patient</div>
                <div>
                  <input className="rounded-md bg-green-500 " checked type="checkbox" />
                </div>
              </div>
              <div className="flex border w-full rounded-md py-2 px-3 justify-between">
                <div className="text-sm text-gray-800">Primary Care Provider</div>
                <div>
                  <input className="rounded-md" type="checkbox" />
                </div>
              </div>
              <div className="flex border w-full rounded-md py-2 px-3 justify-between">
                <div className="text-sm text-gray-800">Behavioral Care Provider</div>
                <div>
                  <input className="rounded-md" type="checkbox" />
                </div>
              </div>
              <div className="flex border w-full rounded-md py-2 px-3 justify-between">
                <div className="text-sm text-gray-800">Company-representative</div>
                <div>
                  <input className="rounded-md" type="checkbox" />
                </div>
              </div>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default UserRoles
