import React from 'react'
import SectionItem from '../../../components/sectionitem/view'

const EmployerViewGeneral = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem title={'Eligibility Code'} value={'2C3-2BF'} />
      <SectionItem title={'Company Name'} value={'Foxtrot'} />
      <SectionItem title={'Company Officer'} value={'Albert Flores'} />
      <SectionItem title={'Company Phone'} value={'+123456789112'} />
      <SectionItem title={'Description'} value={''} />
    </div>
  )
}

export default EmployerViewGeneral
