import React from 'react'
import Tabs from '../../../../components/tabs'
import RequestImagingViewInfo from './info'
import RequestImagesViewData from './data'

const pageTabs = [
  { sort: 1, name: 'Request info', current: false, component: <RequestImagingViewInfo /> },
  { sort: 2, name: 'Request data', current: false, component: <RequestImagesViewData /> },
]

const RequestLabView = () => {
  return (
    <div className="space-y-8">
      <div className="flex justify-end">
        <button className="rounded-full bg-gray-300 flex items-center px-4 py-2 space-x-2">
          <span className="inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 17l-5-5m0 0l5-5m-5 5h12"
              />
            </svg>
          </span>
          <span className="inline-block text-sm font-light font-medium">Back to list</span>
        </button>
      </div>
      <Tabs editLink={'/dashboard/request/lab/edit'} tabs={pageTabs} sideSection={true} />
    </div>
  )
}

export default RequestLabView
