import React from 'react'
import { notifications } from '../header/data'
import NotificationItem from '../notification-item'

interface Props {
  isOpen: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
}

const NotificationModal = (props: Props) => {
  return (
    <>
      <div
        className={`fixed min-h-screen min-w-screen z-10 translate transition-all duration-500 ${
          props.isOpen ? 'opacity-100 z-1 ' : 'opacity-0 -z-10'
        } inset-0 overflow-y-auto m-0`}
        aria-labelledby="modal-title"
        role="dialog"
        aria-modal="true"
        style={{ margin: 0 }}
      >
        <div
          className="fixed inset-0 bg-black bg-opacity-75 transition-opacity"
          aria-hidden="true"
        />

        <div className="w-full h-full flex justify-end overflow-scroll">
          <div
            className={`relative inline-block bg-white overflow-hidden w-[26rem] px-3 py-8 space-y-5 overflow-scroll transition-all duration-500 ${
              props.isOpen ? 'translate-x-0' : 'translate-x-full'
            }`}
          >
            <div className="flex items-center justify-between">
              <div className="font-semibold text-lg px-3">Notifications</div>
              <button onClick={() => props.setOpen(!props.isOpen)} className="">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-5 w-5"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth={2}
                >
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>

            <div className="flex-1 divide divide-y border-b">
              {notifications.map((notification) => {
                return (
                  <NotificationItem
                    time={notification.time}
                    date={notification.date}
                    title={notification.title}
                    icon={notification.icon}
                  />
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default NotificationModal
