import React from 'react'

interface Props {
  children: React.ReactNode | React.ReactNode[]
}

const TableRow = ({ children }: Props) => {
  return <tr className="cursor-pointer hover:bg-gray-50">{children}</tr>
}

export default TableRow
