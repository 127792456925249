import React from 'react'
import BrokerViewGeneral from './general'
import BrokerViewAddress from './address'
import BrokerViewReferralsAndCommissions from './referrals'
import Tabs from '../../../components/tabs'
import UserCard from '../../../components/usercard'
import BackButton from '../../../components/buttons/back-button'

const pageTabs = [
  { sort: 1, name: 'General', current: false, component: <BrokerViewGeneral /> },
  { sort: 2, name: 'Address', current: false, component: <BrokerViewAddress /> },
  {
    sort: 3,
    name: 'Referrals & Commissions',
    current: false,
    component: <BrokerViewReferralsAndCommissions />,
  },
]

const BrokerView = () => {
  return (
    <div className="space-y-8">
      <BackButton />
      <UserCard />
      <Tabs tabs={pageTabs} sideSection={true} />
    </div>
  )
}

export default BrokerView
