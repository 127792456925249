import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

interface Tab {
  sort: number
  name: string
  current: boolean
  component: React.ReactNode
}

interface Props {
  tabs: Tab[]
  sideSection?: boolean
  editLink?: string
}

function classNames(...classes: any) {
  return classes.filter(Boolean).join(' ')
}

const Tabs = (props: Props) => {
  const navigate = useNavigate()

  const [tabs, setTabs] = React.useState<Tab[]>(props.tabs)

  useEffect(() => {
    setCurrentTab({ ...props.tabs[0], current: true })
  }, [])

  const setCurrentTab = (currentTab: Tab) => {
    const newTabs = props.tabs.filter((tab) => tab.name !== currentTab.name)
    setTabs([...newTabs, currentTab])
  }

  const showCurrentTabPage = () => {
    const [currentTab] = tabs.filter((tab) => tab.current)
    return currentTab ? currentTab.component : null
  }

  return (
    <div className="bg-white px-8 py-6 rounded-lg space-y-6 flex-1">
      <div className="border-b border-gray-200 flex items-center ">
        <div className="flex-1">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {tabs
              .sort((tab1, tab2) => tab1.sort - tab2.sort)
              .map((tab) => (
                <div
                  key={tab.name}
                  onClick={() => setCurrentTab({ ...tab, current: true })}
                  className={classNames(
                    tab.current
                      ? 'border-riverBlue text-riverBlue'
                      : 'border-transparent text-gray-400 hover:text-riverBlue hover:border-riverBlue',
                    'whitespace-nowrap py-4 px-1 border-b-2 font-light text-sm cursor-pointer'
                  )}
                  aria-current={tab.current ? 'page' : undefined}
                >
                  {tab.name}
                </div>
              ))}
          </nav>
        </div>
        {props.sideSection && (
          <button
            onClick={() => navigate(props.editLink ?? '')}
            className="bg-riverBlue rounded-lg text-white flex items-center px-5 py-2 space-x-3"
          >
            <span className="inline-block">
              <svg
                className="w-4 h-4"
                viewBox="0 0 12 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.63017 1.79962L2.52517 6.14462C2.37017 6.30962 2.22017 6.63462 2.19017 6.85962L2.00517 8.47962C1.94017 9.06462 2.36017 9.46462 2.94017 9.36462L4.55017 9.08962C4.77517 9.04962 5.09017 8.88462 5.24517 8.71462L9.35017 4.36962C10.0602 3.61962 10.3802 2.76462 9.27517 1.71962C8.17517 0.68462 7.34017 1.04962 6.63017 1.79962Z"
                  stroke="white"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M5.94482 2.52539C6.15982 3.90539 7.27982 4.96039 8.66982 5.10039"
                  stroke="white"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <path
                  d="M1.5 11H10.5"
                  stroke="white"
                  strokeMiterlimit="10"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
              </svg>
            </span>
            <span className="inline-block text-sm font-normal">Edit</span>
          </button>
        )}
      </div>
      {showCurrentTabPage()}
    </div>
  )
}

export default Tabs
