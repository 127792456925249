import React from 'react'
import Tabs from '../../../components/tabs'
import UserCard from '../../../components/usercard'
import UserPatientEdit from './patient-data'
import UserEditGeneral from './general'
import UserEditAddress from './address'
import UserEditProviders from './providers'
import BackButton from '../../../components/buttons/back-button'

const pageTabs = [
  { sort: 1, name: 'General', current: false, component: <UserEditGeneral /> },
  { sort: 2, name: 'Address', current: false, component: <UserEditAddress /> },
  { sort: 3, name: 'Patient data', current: false, component: <UserPatientEdit /> },
  { sort: 4, name: 'Providers', current: false, component: <UserEditProviders /> },
]

const UserEdit = () => {
  return (
    <div className="space-y-8">
      <BackButton />

      <UserCard />
      <Tabs tabs={pageTabs} />
    </div>
  )
}

export default UserEdit
