import React from 'react'
import SectionItem from '../../../../components/sectionitem/view'

const RequestImagesViewData = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem title={'Name'} value={'Leslie Alexander'} />
      <SectionItem title={'D.O.B'} value={'16-08-2021'} />
      <SectionItem title={'Email'} value={'lesliealex@gmail.com'} />
      <SectionItem title={'Zip code'} value={'12017'} />
    </div>
  )
}

export default RequestImagesViewData
