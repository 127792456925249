import React from 'react'

export const notifications = [
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
  {
    time: '09:25am',
    date: 'yesterday',
    title: 'New imaging request',
    icon: (
      <svg className="w-5 h-5" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.573 5.98196C6.21518 5.98196 5.91846 5.68523 5.91846 5.32741V2.70923C5.91846 2.35141 6.21518 2.05469 6.573 2.05469C6.93082 2.05469 7.22755 2.35141 7.22755 2.70923V5.32741C7.22755 5.68523 6.93082 5.98196 6.573 5.98196Z"
          fill="#FF6045"
        />
        <path
          d="M13.5549 5.98196C13.1971 5.98196 12.9004 5.68523 12.9004 5.32741V2.70923C12.9004 2.35141 13.1971 2.05469 13.5549 2.05469C13.9128 2.05469 14.2095 2.35141 14.2095 2.70923V5.32741C14.2095 5.68523 13.9128 5.98196 13.5549 5.98196Z"
          fill="#FF6045"
        />
        <path
          opacity="0.4"
          d="M17.9186 8.38219V15.8004C17.9186 18.4186 16.6095 20.164 13.5549 20.164H6.57311C3.51856 20.164 2.20947 18.4186 2.20947 15.8004V8.38219C2.20947 5.76401 3.51856 4.01855 6.57311 4.01855H13.5549C16.6095 4.01855 17.9186 5.76401 17.9186 8.38219Z"
          fill="#FF6045"
        />
        <path
          d="M11.8094 11.2183H6.573C6.21518 11.2183 5.91846 10.9215 5.91846 10.5637C5.91846 10.2059 6.21518 9.90918 6.573 9.90918H11.8094C12.1672 9.90918 12.4639 10.2059 12.4639 10.5637C12.4639 10.9215 12.1672 11.2183 11.8094 11.2183Z"
          fill="#FF6045"
        />
        <path
          d="M9.19118 15.582H6.573C6.21518 15.582 5.91846 15.2853 5.91846 14.9275C5.91846 14.5697 6.21518 14.2729 6.573 14.2729H9.19118C9.549 14.2729 9.84573 14.5697 9.84573 14.9275C9.84573 15.2853 9.549 15.582 9.19118 15.582Z"
          fill="#FF6045"
        />
        <path
          d="M18.7912 12.8592C18.0145 12.2396 17.0283 11.873 15.9549 11.873C14.8814 11.873 13.8778 12.2483 13.0923 12.8854C12.0363 13.7145 11.373 15.0149 11.373 16.4549C11.373 17.3101 11.6174 18.1305 12.0363 18.8112C12.3592 19.3436 12.7781 19.8061 13.2756 20.164C14.0261 20.7138 14.9512 21.0367 15.9549 21.0367C17.1156 21.0367 18.1629 20.609 18.9658 19.8934C19.3236 19.5967 19.629 19.2301 19.8734 18.8112C20.2923 18.1305 20.5367 17.3101 20.5367 16.4549C20.5367 14.9974 19.856 13.6971 18.7912 12.8592ZM15.9549 18.6367C15.9549 17.4323 14.9774 16.4549 13.773 16.4549C14.9774 16.4549 15.9549 15.4774 15.9549 14.273C15.9549 15.4774 16.9323 16.4549 18.1367 16.4549C16.9323 16.4549 15.9549 17.4323 15.9549 18.6367Z"
          fill="#FF6045"
        />
      </svg>
    ),
  },
]
