import React from 'react'
import SectionItem from '../../../components/sectionitem/view'

const UsersPatientData = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem title={'Date of birth'} value={'11-11-1990'} />
      <SectionItem title={'Gender'} value={'Female'} />
      <SectionItem
        title={'Remaining visits'}
        value={
          <div className="bg-gray-200 flex items-center space-x-3 px-2 py-1 rounded-full">
            <div className="text-xs text-gray-800">Clinic</div>
            <div className="bg-purple-500 p-2 text-sm text-white rounded-full h-6 w-6 text-xs flex items-center justify-center">
              2
            </div>
          </div>
        }
      />
    </div>
  )
}

export default UsersPatientData
