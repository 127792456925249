import React from 'react'
import SectionItem from '../../../components/sectionitem/view'

const EmployerPlanDetails = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem title={'Plan offered'} value={'100% off from Mental Health subscription'} />
      <SectionItem title={'Percentage covered'} value={'MENTALHEALTH100OFF'} />
      <SectionItem title={'Payroll deduction'} value={'Yes'} />
      <SectionItem title={'Description'} value={''} />
    </div>
  )
}

export default EmployerPlanDetails
