import React, { useEffect, useState } from 'react'
import { Formik } from 'formik'
import RiverLogo from '../../assets/images/logo.svg'
import { useNavigate } from 'react-router-dom'

const Login = () => {
  const navigate = useNavigate()
  return (
    <>
      <div className="w-screen h-screen bg-white grid grid-rows-4 overflow-y-scroll">
        <div className="loginBanner" />
        <div className="row-span-3 px-5">
          <>
            <Formik
              initialValues={{
                phone_number: '',
              }}
              validateOnBlur={false}
              onSubmit={() => {}}
            >
              {(formik) => {
                return (
                  <form onSubmit={formik.handleSubmit}>
                    <div className="sm:w-full sm:max-w-md mx-auto rounded-md shadow-xl -m-16 z-10 bg-white overflow-hidden ">
                      {/*{sendPhone.isLoading && (*/}
                      {/*  <div className="flex h-auto overflow-hidden w-full">*/}
                      {/*    <BarLoader color="#2FAEF3" height={4} width={'100%'} />*/}
                      {/*  </div>*/}
                      {/*)}*/}
                      <div className="py-16 md:px-16 px-10 space-y-16 relative">
                        <div className="flex flex-col justify-center items-center space-y-3">
                          <img src={RiverLogo} className="h-10" alt="river logo" />
                          <h3 className="text-riverMainGray font-medium text-sm">Broker Portal</h3>
                        </div>
                        <div className="center mt-8">
                          <h2 className="font-semibold text-riverBlack text-xl">Welcome Back</h2>
                        </div>
                        <div className="center flex-col space-y-3">
                          <div className="w-full">
                            <div className="w-full mx-auto PhoneInput flex flex-col items-start">
                              <div className="w-full">
                                <input
                                  type="text"
                                  className="border py-3 px-5 text-sm text-center rounded outline-none w-full bg-gray-200"
                                />
                              </div>
                            </div>
                          </div>

                          <p className="font-normal text-riverBlack text-sm text-center">
                            Enter your email and we will send you a link to login
                          </p>
                        </div>

                        <div className="-mt-3">
                          <button
                            onClick={() => navigate('/dashboard/users')}
                            type="button"
                            className="w-full flex justify-center py-3 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-riverBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-riverBlue"
                          >
                            Login
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                )
              }}
            </Formik>
          </>
        </div>
      </div>
    </>
  )
}

export default Login
