import React from 'react'
import Tabs from '../../../../components/tabs'
import BackButton from '../../../../components/buttons/back-button'
import RequestImagingCreateData from './data'
import RequestImagingCreateInfo from './info'

const pageTabs = [
  { sort: 1, name: 'Request data', current: false, component: <RequestImagingCreateData /> },
  { sort: 2, name: 'Request info', current: false, component: <RequestImagingCreateInfo /> },
]

const RequestImagingCreate = () => {
  return (
    <div className="space-y-8 h-full flex flex-col">
      <BackButton />
      <Tabs tabs={pageTabs} />
    </div>
  )
}

export default RequestImagingCreate
