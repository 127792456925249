import React from 'react'
import SectionItem from '../../../components/sectionitem/create'

const UserCreateAddress = () => {
  return (
    <div>
      <div className="">
        <SectionItem title={'Street name'} value={''} />
        <SectionItem title={'Apartment'} value={''} />
        <SectionItem title={'Zip Code'} value={''} />
        <SectionItem title={'State'} value={''} />
        <SectionItem title={'City'} value={''} />
      </div>

      <div className="flex">
        <div className="w-1/4" />
        <div className="space-x-5">
          <button className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light">
            Save Changes
          </button>

          <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
            Discard
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserCreateAddress
