import React from 'react'
import SectionItem from '../../../components/sectionitem/view'

const EmployerViewAddress = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem title={'Street name'} value={'Nii Okai St'} />
      <SectionItem title={'Apartment'} value={'Floor 6'} />
      <SectionItem title={'Zip Code'} value={'3452'} />
      <SectionItem title={'State'} value={'Royal State'} />
      <SectionItem title={'City'} value={'Mexico'} />
    </div>
  )
}

export default EmployerViewAddress
