import React from 'react'
import SectionItem from '../../../components/sectionitem/edit'
import CreateUpdateModal from '../../../components/modals/create-update-modal'

const BrokerEditAddress = () => {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      {open && <CreateUpdateModal type={'Broker'} updated setOpen={setOpen} />}
      <div>
        <div className="divide divide-y divide-gray-200">
          <SectionItem title={'Street name'} value={'Nii Okai St'} />
          <SectionItem title={'Apartment'} value={'Floor 6'} />
          <SectionItem title={'Zip Code'} value={'3452'} />
          <SectionItem title={'State'} value={'Royal State'} />
          <SectionItem title={'City'} value={'New York'} />
        </div>

        <div className="flex">
          <div className="w-1/4" />
          <div className="space-x-5">
            <button
              onClick={() => setOpen(true)}
              className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light"
            >
              Save Changes
            </button>

            <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
              Discard
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default BrokerEditAddress
