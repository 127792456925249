import React from 'react'
import SectionItem from '../../../../components/sectionitem/create'
import CreateUpdateModal from '../../../../components/modals/create-update-modal'

const RequestLabCreateInfo = () => {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      {open && <CreateUpdateModal setOpen={setOpen} created type={'Broker'} />}
      <div>
        <div className="divide divide-y divide-gray-200 min-w-min">
          <SectionItem title={'Name'} value={'Leslie Alexander '} />
          <SectionItem title={'D.O.B'} value={''} />
          <SectionItem title={'Email'} value={''} />
          <SectionItem title={'Zip code'} value={''} />
        </div>
        <div className="flex">
          <div className="w-1/4" />
          <div className="space-x-5">
            <button
              onClick={() => setOpen(true)}
              className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light"
            >
              Save Changes
            </button>

            <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
              Discard
            </button>
          </div>
        </div>
      </div>
    </>
  )
}

export default RequestLabCreateInfo
