import React, { ReactNode } from 'react'

interface Props {
  title: string | ReactNode
  value?: string | ReactNode
}

const View = (props: Props) => {
  return (
    <div className="py-7 flex">
      {React.isValidElement(props.title) ? (
        props.title
      ) : (
        <div className="text-sm font-light text-gray-400 w-1/4">{props.title}</div>
      )}

      {React.isValidElement(props.value) ? (
        props.value
      ) : props.value ? (
        <div className="flex-1 font-normal text-sm">{props.value}</div>
      ) : (
        <div className="flex-1 font-normal text-sm text-gray-400">No info</div>
      )}
    </div>
  )
}

export default View
