import React from 'react'
import NotificationItem from '../notification-item'
import { notifications } from './data'
import NotificationModal from '../modals/notification-modal'

const Header = () => {
  const [openNotification, setOpenNotification] = React.useState<boolean>(false)
  const [openNotificationPane, setOpenNotificationPane] = React.useState<boolean>(false)

  return (
    <>
      <NotificationModal isOpen={openNotificationPane} setOpen={setOpenNotificationPane} />

      <div className="sticky top-0 flex items-center space-x-6 justify-end py-4 bg-white px-8">
        <div className="relative">
          <div
            onClick={() => setOpenNotification(!openNotification)}
            className="relative bg-white h-8 w-8 rounded-full flex items-center justify-center cursor-pointer border "
          >
            <div className="pip">3</div>
            <div className="text-riverLightBlack">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-4 w-4"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"
                />
              </svg>
            </div>
          </div>
          {openNotification && (
            <div className="bg-white absolute top-10 w-[23rem] px-3 pt-7 pb-4 -right-1 rounded-[2rem] shadow-lg border border-gray-100 flex flex-col space-y-4">
              <div className="font-medium text-base tracking-wide px-3">Notification</div>
              <div className="flex-1 divide divide-y border-b">
                {notifications.slice(0, 3).map((notification) => {
                  return (
                    <NotificationItem
                      time={notification.time}
                      date={notification.date}
                      title={notification.title}
                      icon={notification.icon}
                    />
                  )
                })}
              </div>
              <div className="py-3 center">
                <div
                  onClick={() => {
                    setOpenNotification(!openNotification)
                    setOpenNotificationPane(true)
                  }}
                  className="text-sm text-riverBlue font-medium cursor-pointer hover:underline"
                >
                  See all
                </div>
              </div>
            </div>
          )}
        </div>

        <div className="bg-white h-10 w-72 border border-gray-100 rounded-lg cursor-pointer flex items-center px-3 space-x-4 ">
          <div className="bg-riverBlue h-5 w-5 text-xs rounded-full flex items-center justify-center text-white font-bold">
            R
          </div>
          <div className="font-normal text-sm flex-1">River Health</div>
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M19 9l-7 7-7-7"
              />
            </svg>
          </div>
        </div>
      </div>
    </>
  )
}

export default Header
