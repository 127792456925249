import React, { ReactNode } from 'react'

interface Props {
  content: ReactNode | string | ReactNode[]
  event?: () => void
  cursor?: boolean
}

const TableCol = (props: Props) => {
  return (
    <td className={`broker-user_col ${props.cursor && 'cursor-pointer'}`} onClick={props.event}>
      {props.content}
    </td>
  )
}

export default TableCol
