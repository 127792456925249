import React from 'react'
import UsersViewGeneral from './general'
import UsersViewAddress from './address'
import Tabs from '../../../components/tabs'
import UserCard from '../../../components/usercard'
import UsersPatientData from './patient-data'
import UserProviders from './providers'
import UserRoles from './roles'
import BackButton from '../../../components/buttons/back-button'

const pageTabs = [
  { sort: 1, name: 'General', current: false, component: <UsersViewGeneral /> },
  { sort: 2, name: 'Address', current: false, component: <UsersViewAddress /> },
  {
    sort: 3,
    name: 'Patient data',
    current: false,
    component: <UsersPatientData />,
  },
  {
    sort: 4,
    name: 'Providers',
    current: false,
    component: <UserProviders />,
  },
  {
    sort: 5,
    name: 'Roles',
    current: false,
    component: <UserRoles />,
  },
]

const UsersView = () => {
  return (
    <div className="space-y-6 h-full flex flex-col">
      <BackButton />

      <UserCard subscribed />
      <Tabs tabs={pageTabs} sideSection={true} />
    </div>
  )
}

export default UsersView
