import React from 'react'
import SectionItem from '../../../components/sectionitem/view'
import Avatar from '../../../assets/images/avatar.png'

const EmployerViewUsers = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem
        title={
          <div className="w-1/4">
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 overflow-hidden rounded-full">
                <img
                  className="w-full h-full object-cover object-center"
                  src={Avatar}
                  alt="avatar image"
                />
              </div>
              <div className="text-sm font-light text-gray-400">Kathryn Murphy</div>
            </div>
          </div>
        }
        value={
          <div className="flex space-x-20">
            <div className="space-y-2">
              <h4 className="text-sm text-gray-400">D.O.B</h4>
              <p className="text-sm text-gray-900">07-12-1990</p>
            </div>
            <div className="space-y-2">
              <h4 className="text-sm text-gray-400">Sign up Date</h4>
              <p className="text-sm text-gray-900">07-12-1990</p>
            </div>
          </div>
        }
      />

      <SectionItem
        title={
          <div className="w-1/4">
            <div className="flex items-center space-x-3">
              <div className="w-10 h-10 overflow-hidden rounded-full">
                <img
                  className="w-full h-full object-cover object-center"
                  src={Avatar}
                  alt="avatar image"
                />
              </div>
              <div className="text-sm font-light text-gray-400">Adam Williams</div>
            </div>
          </div>
        }
        value={
          <div className="flex space-x-20">
            <div className="space-y-2">
              <h4 className="text-sm text-gray-400">D.O.B</h4>
              <p className="text-sm text-gray-900">07-12-1990</p>
            </div>
            <div className="space-y-2">
              <h4 className="text-sm text-gray-400">Sign up Date</h4>
              <p className="text-sm text-gray-900">07-12-1990</p>
            </div>
          </div>
        }
      />
    </div>
  )
}

export default EmployerViewUsers
