import React from 'react'

interface TableHeader {
  sort: number
  name: string
}

interface Props {
  headers: TableHeader[]
  children: React.ReactNode | React.ReactNode[]
}

const ListingTable = (props: Props) => {
  return (
    <div>
      <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="overflow-hidden border-gray-200 sm:rounded-lg">
            <table className="min-w-full">
              <thead className="bg-light-blue">
                <tr>
                  {props.headers
                    .sort((header1, header2) => header1.sort - header2.sort)
                    .map((header) => {
                      return (
                        <th scope="col" className="broker-user_col-header">
                          {header.name}
                        </th>
                      )
                    })}
                </tr>
              </thead>
              <tbody className="bg-white divide-y divide-gray-200">{props.children}</tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ListingTable
