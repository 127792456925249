import React from 'react'
import Layout from './components/layout'
import { Routes, Route } from 'react-router-dom'
import Login from './pages/auth/login'

function App() {
  return (
    <Routes>
      <Route path={'/'} element={<Login />} />
      <Route path={'dashboard/*'} element={<Layout />} />
    </Routes>
  )
}

export default App
