export const employers = [
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
  {
    eligibity_code: '2C3-2BF',
    company_name: 'Foxtrot',
    company_officer: 'Albert Flores',
    address: '6391 Elgin St. Celina, Delaware ',
  },
]
