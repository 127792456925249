import React from 'react'
import Header from '../header'
import { Routes, Route } from 'react-router-dom'
import { routes } from '../../pages/routes'

const Main = () => {
  return (
    <div className="main h-full w-full flex-1 overflow-y-auto flex flex-col">
      <Header />
      <main className="p-8 flex-1 h-full ">
        <Routes>
          {routes.map((route, index) => {
            if (route.children) {
              return (
                <Route key={index} path={route.link} element={route.component}>
                  {route.children.map((routeChild, index) => {
                    return (
                      <Route key={index} path={routeChild.link} element={routeChild.component} />
                    )
                  })}
                </Route>
              )
            }
            return <Route key={index} path={route.link} element={route.component} />
          })}
        </Routes>
      </main>
    </div>
  )
}

export default Main
