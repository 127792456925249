import React from 'react'
import Tabs from '../../../../components/tabs'
import BackButton from '../../../../components/buttons/back-button'
import RequestLabCreateData from './data'
import RequestLabCreateInfo from './info'

const pageTabs = [
  { sort: 1, name: 'Request data', current: false, component: <RequestLabCreateData /> },
  { sort: 2, name: 'Request info', current: false, component: <RequestLabCreateInfo /> },
]

const RequestLabCreate = () => {
  return (
    <div className="space-y-8 h-full flex flex-col">
      <BackButton />
      <Tabs tabs={pageTabs} />
    </div>
  )
}

export default RequestLabCreate
