import React from 'react'
import SectionItem from '../../../components/sectionitem/create'

const UserPatientCreate = () => {
  return (
    <div>
      <div className="">
        <SectionItem
          title={'Date of birth'}
          value={
            <div className="flex-1">
              <input
                type="date"
                className={
                  'rounded-lg border border-gray-200 text-sm outline-none py-2 px-4 min-w-[40%] appearance-none'
                }
                defaultValue={''}
              />
            </div>
          }
        />
        <SectionItem
          title={'Gender'}
          value={
            <div className="flex-1">
              <select
                className={
                  'rounded-lg border border-gray-200 text-sm outline-none py-2 px-4 min-w-[40%] appearance-none '
                }
              >
                <option className="text-gray-500">Select Gender</option>
                <option value="male">Male</option>
                <option value="female">Femaile</option>
              </select>
            </div>
          }
        />
        <SectionItem
          title={'Remaining visit'}
          value={
            <div className="flex-1">
              <div className="min-w-[40%] w-1/4 grid grid-cols-2 gap-4">
                <div
                  className={
                    'bg-gray-100 flex justify-start items-center rounded-lg px-4 text-gray-500 text-sm'
                  }
                >
                  Clinic
                </div>
                <div className="w-full">
                  <input
                    type="number"
                    className={
                      'rounded-lg border border-gray-200 text-sm outline-none py-2 px-4 appearance-none w-full'
                    }
                    placeholder={'0'}
                    defaultValue={''}
                  />
                </div>
              </div>
            </div>
          }
        />
      </div>

      <div className="flex mt-5">
        <div className="w-1/4" />
        <div className="space-x-5">
          <button className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light">
            Save Changes
          </button>

          <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
            Discard
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserPatientCreate
