import React from 'react'
import SideNavItem from '../sidebarnav'
import { useNavigate } from 'react-router-dom'

const SideBar = () => {
  const navigate = useNavigate()
  return (
    <div className="sidebar">
      <div className="font-semibold text-lg">Mountain Health</div>
      <div className="flex-1 pt-28">
        <div className="space-y-2">
          <SideNavItem
            link={'/dashboard/users'}
            item={'Users'}
            icon={
              <svg
                className="h-6 w-6 stroke-current fill-current"
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  opacity="0.4"
                  d="M9.5 2C6.88 2 4.75 4.13 4.75 6.75C4.75 9.32 6.76 11.4 9.38 11.49C9.46 11.48 9.54 11.48 9.6 11.49C9.62 11.49 9.63 11.49 9.65 11.49C9.66 11.49 9.66 11.49 9.67 11.49C12.23 11.4 14.24 9.32 14.25 6.75C14.25 4.13 12.12 2 9.5 2Z"
                />
                <path d="M14.58 14.1499C11.79 12.2899 7.23996 12.2899 4.42996 14.1499C3.15996 14.9999 2.45996 16.1499 2.45996 17.3799C2.45996 18.6099 3.15996 19.7499 4.41996 20.5899C5.81996 21.5299 7.65996 21.9999 9.49996 21.9999C11.34 21.9999 13.18 21.5299 14.58 20.5899C15.84 19.7399 16.54 18.5999 16.54 17.3599C16.53 16.1299 15.84 14.9899 14.58 14.1499Z" />
                <path
                  opacity="0.4"
                  d="M20.4901 7.3401C20.6501 9.2801 19.2701 10.9801 17.3601 11.2101C17.3501 11.2101 17.3501 11.2101 17.3401 11.2101H17.3101C17.2501 11.2101 17.1901 11.2101 17.1401 11.2301C16.1701 11.2801 15.2801 10.9701 14.6101 10.4001C15.6401 9.4801 16.2301 8.1001 16.1101 6.6001C16.0401 5.7901 15.7601 5.0501 15.3401 4.4201C15.7201 4.2301 16.1601 4.1101 16.6101 4.0701C18.5701 3.9001 20.3201 5.3601 20.4901 7.3401Z"
                />
                <path d="M22.49 16.5899C22.41 17.5599 21.79 18.3999 20.75 18.9699C19.75 19.5199 18.49 19.7799 17.24 19.7499C17.96 19.0999 18.38 18.2899 18.46 17.4299C18.56 16.1899 17.97 14.9999 16.79 14.0499C16.12 13.5199 15.34 13.0999 14.49 12.7899C16.7 12.1499 19.48 12.5799 21.19 13.9599C22.11 14.6999 22.58 15.6299 22.49 16.5899Z" />
              </svg>
            }
          />

          <SideNavItem
            link={'/dashboard/employers'}
            item={'Employers'}
            icon={
              <svg
                className="h-6 w-6 stroke-current fill-current"
                fill="none"
                viewBox="0 0 25 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <g>
                  <path
                    d="m18.03 7.77c-.07-.01-.14-.01-.21 0-1.55-.05-2.78-1.32-2.78-2.88 0-1.59 1.29-2.89 2.89-2.89 1.59 0 2.89 1.29 2.89 2.89-.01 1.56-1.24 2.83-2.79 2.88z"
                    opacity=".4"
                  />
                  <path
                    d="m21.2901 14.6999c-1.12.75-2.69 1.03-4.14.84.38-.82.58-1.73.59-2.69 0-1-.22-1.95-.64-2.78 1.48-.19998 3.05.08 4.18.83 1.58 1.04 1.58 2.75.01 3.8z"
                    opacity=".4"
                  />
                  <path
                    d="m6.9399 7.77c.07-.01.14-.01.21 0 1.55-.05 2.78-1.32 2.78-2.88 0-1.59-1.29-2.89-2.89-2.89-1.59 0-2.89 1.29-2.89 2.89.01 1.56 1.24 2.83 2.79 2.88z"
                    opacity=".4"
                  />
                  <path
                    d="m7.05012 12.8501c0 .97.21 1.89.59 2.72-1.41.15-2.88-.15-3.96-.86-1.58-1.05-1.58-2.76 0-3.81 1.07-.72 2.58-1.01 4-.85-.41.84-.63 1.79-.63 2.8z"
                    opacity=".4"
                  />
                  <path d="m12.62 15.87c-.08-.01-.1699-.01-.2599 0-1.84-.06-3.31005-1.57-3.31005-3.43 0-1.9 1.53005-3.44 3.44005-3.44 1.9 0 3.4399 1.54 3.4399 3.44 0 1.86-1.46 3.37-3.31 3.43z" />
                  <path d="m9.37005 17.9401c-1.51 1.01-1.51 2.67 0 3.67 1.71995 1.15 4.54005 1.15 6.26005 0 1.51-1.01 1.51-2.67 0-3.67-1.71-1.15-4.5301-1.15-6.26005 0z" />
                </g>
              </svg>
            }
          />

          <SideNavItem
            link={'/dashboard/broker'}
            item={'Broker'}
            icon={
              <svg
                className="w-6 h-6 stoke-current fill-current "
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M11 19.9V4.1C11 2.6 10.36 2 8.77 2H4.73C3.14 2 2.5 2.6 2.5 4.1V19.9C2.5 21.4 3.14 22 4.73 22H8.77C10.36 22 11 21.4 11 19.9Z" />
                <path
                  opacity="0.4"
                  d="M21.5 10.9V4.1C21.5 2.6 20.86 2 19.27 2H15.23C13.64 2 13 2.6 13 4.1V10.9C13 12.4 13.64 13 15.23 13H19.27C20.86 13 21.5 12.4 21.5 10.9Z"
                />
                <path
                  opacity="0.4"
                  d="M21.5 19.9V17.1C21.5 15.6 20.86 15 19.27 15H15.23C13.64 15 13 15.6 13 17.1V19.9C13 21.4 13.64 22 15.23 22H19.27C20.86 22 21.5 21.4 21.5 19.9Z"
                />
              </svg>
            }
          />

          <SideNavItem
            link={'/dashboard/request'}
            item={'Request'}
            dropDown={true}
            icon={
              <svg
                className="w-6 h-6 stoke-current fill-current "
                viewBox="0 0 25 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M7.5 5.75C7.09 5.75 6.75 5.41 6.75 5V2C6.75 1.59 7.09 1.25 7.5 1.25C7.91 1.25 8.25 1.59 8.25 2V5C8.25 5.41 7.91 5.75 7.5 5.75Z" />
                <path d="M15.5 5.75C15.09 5.75 14.75 5.41 14.75 5V2C14.75 1.59 15.09 1.25 15.5 1.25C15.91 1.25 16.25 1.59 16.25 2V5C16.25 5.41 15.91 5.75 15.5 5.75Z" />
                <path
                  opacity="0.4"
                  d="M20.5 8.5V17C20.5 20 19 22 15.5 22H7.5C4 22 2.5 20 2.5 17V8.5C2.5 5.5 4 3.5 7.5 3.5H15.5C19 3.5 20.5 5.5 20.5 8.5Z"
                />
                <path d="M13.5 11.75H7.5C7.09 11.75 6.75 11.41 6.75 11C6.75 10.59 7.09 10.25 7.5 10.25H13.5C13.91 10.25 14.25 10.59 14.25 11C14.25 11.41 13.91 11.75 13.5 11.75Z" />
                <path d="M10.5 16.75H7.5C7.09 16.75 6.75 16.41 6.75 16C6.75 15.59 7.09 15.25 7.5 15.25H10.5C10.91 15.25 11.25 15.59 11.25 16C11.25 16.41 10.91 16.75 10.5 16.75Z" />
                <path d="M21.5 13.63C20.61 12.92 19.48 12.5 18.25 12.5C17.02 12.5 15.87 12.93 14.97 13.66C13.76 14.61 13 16.1 13 17.75C13 18.73 13.28 19.67 13.76 20.45C14.13 21.06 14.61 21.59 15.18 22C16.04 22.63 17.1 23 18.25 23C19.58 23 20.78 22.51 21.7 21.69C22.11 21.35 22.46 20.93 22.74 20.45C23.22 19.67 23.5 18.73 23.5 17.75C23.5 16.08 22.72 14.59 21.5 13.63ZM18.25 20.25C18.25 18.87 17.13 17.75 15.75 17.75C17.13 17.75 18.25 16.63 18.25 15.25C18.25 16.63 19.37 17.75 20.75 17.75C19.37 17.75 18.25 18.87 18.25 20.25Z" />
              </svg>
            }
          />
        </div>
      </div>
      <div className="space-y-6 px-5">
        <div className="px-6">
          <hr />
        </div>

        <div className="flex space-x-6 items-center cursor-pointer">
          <div>
            <svg
              className="w-5 h-5"
              viewBox="0 0 25 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.9399 14.62L20.4999 12.06L17.9399 9.5"
                stroke="#7B8A93"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M10.26 12.0601H20.43"
                stroke="#7B8A93"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12.26 20C7.84001 20 4.26001 17 4.26001 12C4.26001 7 7.84001 4 12.26 4"
                stroke="#7B8A93"
                strokeWidth="1.5"
                strokeMiterlimit="10"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
          <div onClick={() => navigate('/')} className="font-medium text-riverLightBlack">
            Sign Out
          </div>
        </div>
      </div>
    </div>
  )
}

export default SideBar
