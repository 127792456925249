import React from 'react'
import { useNavigate } from 'react-router-dom'
import EditButton from '../../components/editbutton'
import { employers } from './data/employers.data'

const Overview = () => {
  const navigate = useNavigate()

  return (
    <div className="space-y-8">
      <div className="bg-white px-8 py-6 rounded-lg space-y-6">
        <div className="w-full flex justify-between">
          <div className="flex-1 flex space-x-8">
            <div className="search-container">
              <span className="inline-block">
                <svg
                  className="w-4 h-4"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66671 14.0002C11.1645 14.0002 14 11.1646 14 7.66683C14 4.16903 11.1645 1.3335 7.66671 1.3335C4.1689 1.3335 1.33337 4.16903 1.33337 7.66683C1.33337 11.1646 4.1689 14.0002 7.66671 14.0002Z"
                    stroke="#CDD5D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.6667 14.6668L13.3334 13.3335"
                    stroke="#CDD5D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input
                placeholder="Search by name, surname, etc."
                type="text"
                className="bg-transparent outline-none text-sm w-full"
              />
            </div>
            <button className="border-2 border-gray-100 flex items-center px-6 rounded-lg space-x-3">
              <span className="inline-block">
                <svg
                  className="w-4 h-4"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.5 3.5H10.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M3 6H9" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M5 8.5H7" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
              <span className="inline-block text-sm">Filter</span>
            </button>
          </div>
          <button
            onClick={() => navigate('create')}
            className="bg-riverBlue rounded-lg text-white flex items-center px-4 space-x-2"
          >
            <span className="inline-block">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                />
              </svg>
            </span>
            <span className="inline-block text-sm font-normal ">New User</span>
          </button>
        </div>

        <div className="flex flex-col">
          <div className="overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="overflow-hidden border-b border-gray-200 sm:rounded-lg">
                <table className="min-w-full">
                  <thead className="bg-light-blue">
                    <tr>
                      <th scope="col" className="broker-user_col-header">
                        Eligibility Code
                      </th>
                      <th scope="col" className="broker-user_col-header">
                        Company Name
                      </th>
                      <th scope="col" className="broker-user_col-header">
                        Company Officer
                      </th>
                      <th scope="col" className="broker-user_col-header">
                        Address
                      </th>
                      <th scope="col" className="broker-user_col-header">
                        Action
                      </th>
                    </tr>
                  </thead>
                  <tbody className="bg-white divide-y divide-gray-200">
                    {employers.map((employer) => (
                      <tr key={employer.eligibity_code} className="cursor-pointer">
                        <td
                          className="broker-user_col cursor-pointer "
                          onClick={() => navigate('view')}
                        >
                          {employer.eligibity_code}
                        </td>
                        <td
                          className="broker-user_col cursor-pointer"
                          onClick={() => navigate('view')}
                        >
                          {employer.company_name}
                        </td>
                        <td
                          className="broker-user_col cursor-pointer"
                          onClick={() => navigate('view')}
                        >
                          {employer.company_officer}
                        </td>
                        <td
                          className="broker-user_col cursor-pointer"
                          onClick={() => navigate('view')}
                        >
                          {employer.address}
                        </td>
                        <td className="broker-user_col ">
                          <EditButton buttonEvent={() => navigate('edit')} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Overview
