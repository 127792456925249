import React from 'react'
import Tabs from '../../../components/tabs'
import BrokerCreateAddress from './address'
import BrokerCreateGeneral from './general'
import BackButton from '../../../components/buttons/back-button'

const pageTabs = [
  { sort: 1, name: 'General', current: false, component: <BrokerCreateGeneral /> },
  { sort: 2, name: 'Address', current: false, component: <BrokerCreateAddress /> },
]

const BrokerCreate = () => {
  return (
    <div className="space-y-8 h-full flex flex-col">
      <BackButton />
      <Tabs tabs={pageTabs} />
    </div>
  )
}

export default BrokerCreate
