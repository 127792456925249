import React from 'react'
import Tabs from '../../../components/tabs'
import UserCreateGeneral from './general'
import UserCreateAddress from './address'
import UserPatientCreate from './patient-data'
import UserCreateProviders from './providers'
import UserCreateRole from './roles'

const pageTabs = [
  { sort: 1, name: 'General', current: false, component: <UserCreateGeneral /> },
  { sort: 2, name: 'Address', current: false, component: <UserCreateAddress /> },
  { sort: 3, name: 'Patient', current: false, component: <UserPatientCreate /> },
  { sort: 4, name: 'Providers', current: false, component: <UserCreateProviders /> },
  { sort: 5, name: 'Roles', current: false, component: <UserCreateRole /> },
]

const UserCreate = () => {
  return (
    <div className="space-y-8">
      <div className="flex justify-end">
        <button className="rounded-full bg-gray-300 flex items-center px-4 py-2 space-x-2">
          <span className="inline-block">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M11 17l-5-5m0 0l5-5m-5 5h12"
              />
            </svg>
          </span>
          <span className="inline-block text-sm font-light font-medium">Back to list</span>
        </button>
      </div>
      <Tabs tabs={pageTabs} />
    </div>
  )
}

export default UserCreate
