import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'

interface DropDownItems {
  item: string
  link: string
}

interface Props {
  item: string
  icon: React.ReactNode | React.ReactNode[]
  dropDown: boolean
  link: string
  dropDownItems?: DropDownItems[]
}

const SideNavItem = (props: Props) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false)

  const location = useLocation()

  React.useEffect(() => {
    if (props.dropDown) {
      if (location.pathname.includes(props.link)) setIsOpen(true)
    }
  }, [])

  return (
    <>
      {!props.dropDown ? (
        <NavLink
          to={props.link}
          className={({ isActive }) => (isActive ? 'active' : 'sidenav-item ')}
        >
          <div> {props.icon}</div>
          <div className="font-medium flex-1">{props.item}</div>
        </NavLink>
      ) : (
        <div>
          <div
            onClick={() => setIsOpen(!isOpen)}
            className={`${isOpen ? 'active' : 'sidenav-item'} `}
          >
            <div>{props.icon}</div>
            <div className="font-medium flex-1">{props.item}</div>
            {props.dropDown && (
              <div
                className={`transition-all duration-[250ms] ease-in
              ${isOpen ? 'rotate-90 ' : 'rotate-0'}`}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M9 5l7 7-7 7"
                  />
                </svg>
              </div>
            )}
          </div>

          <ul
            className={`space-y-2 ml-6 mt-2 overflow-hidden transition-all duration-[100ms] ease-in ${
              isOpen ? 'h-44' : 'h-0'
            }`}
          >
            <li>
              <NavLink
                to={`${props.link}/lab`}
                className={({ isActive }) =>
                  isActive ? 'sideNav_dropItem-active' : 'sideNav_dropItem'
                }
              >
                <div>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="currentColor"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path d="M17.1667 16.0503H9.60838C9.23338 16.0503 8.92505 15.7419 8.92505 15.3669C8.92505 14.9919 9.23338 14.6836 9.60838 14.6836H17.1667C17.5417 14.6836 17.85 14.9919 17.85 15.3669C17.85 15.7503 17.5417 16.0503 17.1667 16.0503Z" />
                    <path d="M17.1667 10.8081H9.60838C9.23338 10.8081 8.92505 10.4997 8.92505 10.1247C8.92505 9.74974 9.23338 9.44141 9.60838 9.44141H17.1667C17.5417 9.44141 17.85 9.74974 17.85 10.1247C17.85 10.4997 17.5417 10.8081 17.1667 10.8081Z" />
                    <path d="M17.1667 5.55807H9.60838C9.23338 5.55807 8.92505 5.24974 8.92505 4.87474C8.92505 4.49974 9.23338 4.19141 9.60838 4.19141H17.1667C17.5417 4.19141 17.85 4.49974 17.85 4.87474C17.85 5.24974 17.5417 5.55807 17.1667 5.55807Z" />
                    <path
                      opacity="0.4"
                      d="M4.59169 6.69193C4.40836 6.69193 4.23336 6.61693 4.10836 6.49193L3.35002 5.73359C3.08336 5.46693 3.08336 5.03359 3.35002 4.76693C3.61669 4.50026 4.05002 4.50026 4.31669 4.76693L4.59169 5.04193L6.37503 3.25859C6.64169 2.99193 7.07502 2.99193 7.34169 3.25859C7.60836 3.52526 7.60836 3.95859 7.34169 4.22526L5.07502 6.49193C4.94169 6.61693 4.77502 6.69193 4.59169 6.69193Z"
                    />
                    <path
                      opacity="0.4"
                      d="M4.59169 11.9419C4.41669 11.9419 4.24169 11.8753 4.10836 11.7419L3.35002 10.9836C3.08336 10.7169 3.08336 10.2836 3.35002 10.0169C3.61669 9.75026 4.05002 9.75026 4.31669 10.0169L4.59169 10.2919L6.37503 8.50859C6.64169 8.24193 7.07502 8.24193 7.34169 8.50859C7.60836 8.77526 7.60836 9.20859 7.34169 9.47526L5.07502 11.7419C4.94169 11.8753 4.76669 11.9419 4.59169 11.9419Z"
                    />
                    <path
                      opacity="0.4"
                      d="M4.59169 16.9419C4.41669 16.9419 4.24169 16.8753 4.10836 16.7419L3.35002 15.9836C3.08336 15.7169 3.08336 15.2836 3.35002 15.0169C3.61669 14.7503 4.05002 14.7503 4.31669 15.0169L4.59169 15.2919L6.37503 13.5086C6.64169 13.2419 7.07502 13.2419 7.34169 13.5086C7.60836 13.7753 7.60836 14.2086 7.34169 14.4753L5.07502 16.7419C4.94169 16.8753 4.76669 16.9419 4.59169 16.9419Z"
                    />
                  </svg>
                </div>
                <div className="font-normal flex-1">Lab Request</div>
              </NavLink>
            </li>

            <li>
              <NavLink
                to={`${props.link}/imaging`}
                className={({ isActive }) =>
                  isActive ? 'sideNav_dropItem-active' : 'sideNav_dropItem'
                }
              >
                <div>
                  <svg
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="currentColor"
                    stroke="currentColor"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      opacity="0.4"
                      d="M13.9916 1.66699H7.00829C3.97496 1.66699 2.16663 3.47533 2.16663 6.50866V13.4837C2.16663 16.5253 3.97496 18.3337 7.00829 18.3337H13.9833C17.0166 18.3337 18.825 16.5253 18.825 13.492V6.50866C18.8333 3.47533 17.025 1.66699 13.9916 1.66699Z"
                    />
                    <path d="M11 15.417H14.1667C15.4167 15.417 15.9167 14.917 15.9167 13.667V12.167C15.9167 10.917 15.4167 10.417 14.1667 10.417H11C9.75 10.417 9.25 10.917 9.25 12.167V13.667C9.25 14.917 9.75 15.417 11 15.417Z" />
                  </svg>
                </div>
                <div className="font-normal flex-1">Imaging Request</div>
              </NavLink>
            </li>
          </ul>
        </div>
      )}
    </>
  )
}

SideNavItem.defaultProps = {
  dropDown: false,
}

export default SideNavItem
