import React from 'react'
import SectionItem from '../../../components/sectionitem/edit'

const UserEditGeneral = () => {
  return (
    <div>
      <div className="divide divide-y divide-gray-200 min-w-min">
        <SectionItem title={'First name'} value={'Tifanny'} />
        <SectionItem title={'Last name'} value={'Bemuah'} />
        <SectionItem title={'Email'} value={'tifanny.done@login.com'} />
        <SectionItem title={'Phone'} value={'+123456789112'} />
        <SectionItem title={'Password'} value={'* * * * * *'} />
        <SectionItem inputType={'textarea'} title={'Description'} value={''} />
      </div>
      <div className="flex">
        <div className="w-1/4" />
        <div className="space-x-5">
          <button className="rounded-lg bg-riverBlue py-2 px-6 text-white font-light">
            Save Changes
          </button>

          <button className="rounded-lg bg-riverStatsLightBlue py-2 px-12 text-riverBlue font-light">
            Discard
          </button>
        </div>
      </div>
    </div>
  )
}

export default UserEditGeneral
