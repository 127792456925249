import React from 'react'
import EditButton from '../../components/editbutton'
import { people } from './data/users.data'
import { useNavigate } from 'react-router-dom'
import ListingTable from '../../components/tables/listing-table'
import TableRow from '../../components/tables/table-row'
import TableCol from '../../components/tables/table-col'
import NewButton from '../../components/buttons/new-user'

const UsersOverview = () => {
  const navigate = useNavigate()

  return (
    <div className="space-y-8 flex flex-col h-full">
      <div className="stats">
        <div className="stats-section">
          <div className="bg-riverStatsLightGreen p-6 rounded-full">
            <svg
              className="w-10 h-10"
              viewBox="0 0 38 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M28.5 30.4452H27.2967C26.03 30.4452 24.8267 30.9361 23.94 31.8227L21.2325 34.4986C19.9975 35.7177 17.9867 35.7177 16.7517 34.4986L14.0442 31.8227C13.1575 30.9361 11.9383 30.4452 10.6875 30.4452H9.5C6.87167 30.4452 4.75 28.3395 4.75 25.7428V8.46857C4.75 5.8719 6.87167 3.76611 9.5 3.76611H28.5C31.1283 3.76611 33.25 5.8719 33.25 8.46857V25.7428C33.25 28.3236 31.1283 30.4452 28.5 30.4452Z"
                fill="#72C16D"
              />
              <path
                d="M19 17.0658C21.0375 17.0658 22.6892 15.4142 22.6892 13.3767C22.6892 11.3392 21.0375 9.6875 19 9.6875C16.9625 9.6875 15.3108 11.3392 15.3108 13.3767C15.3108 15.4142 16.9625 17.0658 19 17.0658Z"
                fill="#72C16D"
              />
              <path
                d="M23.2433 24.4283C24.5258 24.4283 25.27 23.0033 24.5575 21.9424C23.4808 20.3433 21.3908 19.2666 18.9999 19.2666C16.6091 19.2666 14.5191 20.3433 13.4424 21.9424C12.7299 23.0033 13.4741 24.4283 14.7566 24.4283H23.2433Z"
                fill="#72C16D"
              />
            </svg>
          </div>
          <div>
            <div className="text-riverLightBlack font-medium text-base">Total Users</div>
            <div className="font-bold">246 People</div>
          </div>
        </div>
        <div className="stats-section">
          <div className="bg-riverStatsLightBlue p-6 rounded-full">
            <svg
              className="w-10 h-10"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M29.7825 9.89543H31.9358C31.635 9.46793 31.3183 9.0721 30.9858 8.67627L29.7825 9.89543Z"
                fill="#2FAEF3"
              />
              <path
                opacity="0.4"
                d="M29.3233 6.99834C28.9274 6.66584 28.5316 6.34917 28.1041 6.04834V8.20167L29.3233 6.99834Z"
                fill="#2FAEF3"
              />
              <path
                opacity="0.4"
                d="M26.9166 12.2707C26.7583 12.2707 26.6158 12.239 26.4574 12.1757C26.1724 12.049 25.9349 11.8273 25.8083 11.5265C25.7449 11.384 25.7133 11.2257 25.7133 11.0673V4.734C25.7133 4.71817 25.7291 4.70233 25.7291 4.67067C23.6866 3.72067 21.4066 3.1665 19 3.1665C10.26 3.1665 3.16663 10.2598 3.16663 18.9998C3.16663 27.7398 10.26 34.8332 19 34.8332C27.74 34.8332 34.8333 27.7398 34.8333 18.9998C34.8333 16.5932 34.2791 14.3132 33.3133 12.2548C33.2975 12.2548 33.2816 12.2707 33.25 12.2707H26.9166Z"
                fill="#2FAEF3"
              />
              <path
                d="M31.0017 8.67646L35.6725 4.00561C36.1317 3.54645 36.1317 2.78645 35.6725 2.32729C35.2134 1.86812 34.4534 1.86812 33.9942 2.32729L29.3234 6.99811C29.925 7.52061 30.4792 8.09062 31.0017 8.67646Z"
                fill="#2FAEF3"
              />
              <path
                d="M28.1041 4.75C28.1041 4.10083 27.5658 3.5625 26.9166 3.5625C26.2833 3.5625 25.7767 4.06917 25.745 4.68667C26.5683 5.0825 27.36 5.52583 28.1041 6.04833V4.75Z"
                fill="#2FAEF3"
              />
              <path
                d="M34.4376 11.0835C34.4376 10.4343 33.8992 9.896 33.2501 9.896H31.9359C32.4584 10.6402 32.9176 11.4318 33.2976 12.2552C33.9309 12.2235 34.4376 11.7168 34.4376 11.0835Z"
                fill="#2FAEF3"
              />
              <path
                d="M21.7708 18.715L20.1875 18.1608V14.6458H20.3141C21.1216 14.6458 21.7708 15.3583 21.7708 16.2292C21.7708 16.8783 22.3091 17.4167 22.9583 17.4167C23.6075 17.4167 24.1458 16.8783 24.1458 16.2292C24.1458 14.0442 22.4358 12.2708 20.3141 12.2708H20.1875V11.875C20.1875 11.2258 19.6491 10.6875 19 10.6875C18.3508 10.6875 17.8125 11.2258 17.8125 11.875V12.2708H17.3375C15.4216 12.2708 13.8541 13.8858 13.8541 15.8808C13.8541 18.1925 15.2 18.9367 16.2291 19.3008L17.8125 19.855V23.37H17.6858C16.8783 23.37 16.2291 22.6575 16.2291 21.7867C16.2291 21.1375 15.6908 20.5992 15.0416 20.5992C14.3925 20.5992 13.8541 21.1375 13.8541 21.7867C13.8541 23.9717 15.5641 25.745 17.6858 25.745H17.8125V26.1408C17.8125 26.79 18.3508 27.3283 19 27.3283C19.6491 27.3283 20.1875 26.79 20.1875 26.1408V25.745H20.6624C22.5783 25.745 24.1458 24.13 24.1458 22.135C24.1458 19.8075 22.8 19.0633 21.7708 18.715ZM17.0049 17.0367C16.4666 16.8467 16.2291 16.7358 16.2291 15.865C16.2291 15.1842 16.7358 14.63 17.3375 14.63H17.8125V17.3058L17.0049 17.0367ZM20.6624 23.3542H20.1875V20.6783L20.995 20.9633C21.5333 21.1533 21.7708 21.2642 21.7708 22.135C21.7708 22.8 21.2641 23.3542 20.6624 23.3542Z"
                fill="#2FAEF3"
              />
            </svg>
          </div>
          <div>
            <div className="text-riverLightBlack font-medium text-base">Subscribed</div>
            <div className="font-bold">152 People</div>
          </div>
        </div>
        <div className="stats-section">
          <div className="bg-riverStatsLightOrange p-6 rounded-full">
            <svg
              className="w-10 h-10"
              viewBox="0 0 38 38"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                opacity="0.4"
                d="M19.0001 34.675C27.7446 34.675 34.8334 27.5861 34.8334 18.8416C34.8334 10.0971 27.7446 3.0083 19.0001 3.0083C10.2556 3.0083 3.16675 10.0971 3.16675 18.8416C3.16675 27.5861 10.2556 34.675 19.0001 34.675Z"
                fill="#FF8C42"
              />
              <path
                d="M22.5783 19L20.1875 18.1608V12.7933H20.7575C22.04 12.7933 23.085 13.9175 23.085 15.295C23.085 15.9442 23.6233 16.4825 24.2725 16.4825C24.9217 16.4825 25.46 15.9442 25.46 15.295C25.46 12.6033 23.3542 10.4183 20.7575 10.4183H20.1875V9.5C20.1875 8.85083 19.6492 8.3125 19 8.3125C18.3508 8.3125 17.8125 8.85083 17.8125 9.5V10.4183H16.7833C14.44 10.4183 12.5242 12.3975 12.5242 14.82C12.5242 17.6542 14.1708 18.5567 15.4217 19L17.8125 19.8392V25.1908H17.2425C15.96 25.1908 14.915 24.0667 14.915 22.6892C14.915 22.04 14.3767 21.5017 13.7275 21.5017C13.0783 21.5017 12.54 22.04 12.54 22.6892C12.54 25.3808 14.6458 27.5658 17.2425 27.5658H17.8125V28.5C17.8125 29.1492 18.3508 29.6875 19 29.6875C19.6492 29.6875 20.1875 29.1492 20.1875 28.5V27.5817H21.2167C23.56 27.5817 25.4758 25.6025 25.4758 23.18C25.46 20.33 23.8133 19.4275 22.5783 19ZM16.2133 16.7675C15.4058 16.4825 14.915 16.2133 14.915 14.8358C14.915 13.7117 15.7542 12.8092 16.7992 12.8092H17.8283V17.3375L16.2133 16.7675ZM21.2167 25.2067H20.1875V20.6783L21.7867 21.2325C22.5942 21.5175 23.085 21.7867 23.085 23.1642C23.085 24.2883 22.2458 25.2067 21.2167 25.2067Z"
                fill="#FF8C42"
              />
            </svg>
          </div>
          <div>
            <div className="text-riverLightBlack font-medium text-base">Unsubscribed</div>
            <div className="font-bold">96 people</div>
          </div>
        </div>
      </div>
      <div className="bg-white px-8 py-6 rounded-lg space-y-6 flex-1">
        <div className="w-full flex justify-between">
          <div className="flex-1 flex space-x-8">
            <div className="search-container">
              <span className="inline-block">
                <svg
                  className="w-4 h-4"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M7.66671 14.0002C11.1645 14.0002 14 11.1646 14 7.66683C14 4.16903 11.1645 1.3335 7.66671 1.3335C4.1689 1.3335 1.33337 4.16903 1.33337 7.66683C1.33337 11.1646 4.1689 14.0002 7.66671 14.0002Z"
                    stroke="#CDD5D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M14.6667 14.6668L13.3334 13.3335"
                    stroke="#CDD5D9"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </span>
              <input
                placeholder="Search by name, surname, etc."
                type="text"
                className="bg-transparent outline-none text-sm w-full"
              />
            </div>
            <button className="border-2 border-gray-100 flex items-center px-6 rounded-lg space-x-3">
              <span className="inline-block">
                <svg
                  className="w-4 h-4"
                  viewBox="0 0 12 12"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path d="M1.5 3.5H10.5" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M3 6H9" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                  <path d="M5 8.5H7" stroke="black" strokeWidth="1.5" strokeLinecap="round" />
                </svg>
              </span>
              <span className="inline-block text-sm">Filter</span>
            </button>
          </div>
          <NewButton />
        </div>

        <div className="flex flex-col">
          <ListingTable
            headers={[
              {
                name: 'Firstname',
                sort: 1,
              },
              {
                name: 'Lastname',
                sort: 2,
              },
              {
                name: 'Phone',
                sort: 3,
              },
              {
                name: 'D.O.B',
                sort: 4,
              },
              {
                name: 'Email',
                sort: 5,
              },
              {
                name: 'Subscription',
                sort: 6,
              },
              {
                name: 'Action',
                sort: 7,
              },
            ]}
          >
            {people.map((person, key) => (
              <TableRow>
                <TableCol event={() => navigate('view')} content={person.firstname} cursor />
                <TableCol event={() => navigate('view')} content={person.lastname} cursor />
                <TableCol event={() => navigate('view')} content={person.phone} cursor />
                <TableCol event={() => navigate('view')} content={person.dob} cursor />
                <TableCol event={() => navigate('view')} content={person.email} cursor />
                <TableCol
                  event={() => navigate('view')}
                  content={
                    person.subscription ? (
                      <div className="bg-green-50 text-sm text-green-500 w-fit px-2 py-1 rounded-full">
                        Subscribed
                      </div>
                    ) : (
                      <div className="bg-red-50 text-sm text-red-500 w-fit px-2 py-2 rounded-full">
                        Cancelled
                      </div>
                    )
                  }
                  cursor
                />

                <TableCol
                  event={() => navigate('view')}
                  content={<EditButton buttonEvent={() => navigate('edit')} />}
                />
              </TableRow>
            ))}
          </ListingTable>
        </div>
      </div>
    </div>
  )
}

export default UsersOverview
