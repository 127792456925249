import React from 'react'
import SectionItem from '../../../../components/sectionitem/view'

const RequestImagingViewInfo = () => {
  return (
    <div className="divide divide-y divide-gray-200">
      <SectionItem title={'#'} value={'101'} />
      <SectionItem title={'Requested for'} value={'Leslie Alexander'} />
      <SectionItem
        title={'RequestedBy'}
        value={
          <div className="flex items-center space-x-3">
            <div>
              <img
                className="w-8 h-8 rounded-full"
                src={
                  'https://images.unsplash.com/photo-1570295999919-56ceb5ecca61?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1000&q=80'
                }
              />
            </div>
            <div>Wade Warren</div>
          </div>
        }
      />
      <SectionItem title={'Date requested'} value={'16-08-2021'} />
      <SectionItem
        title={'Request status'}
        value={
          <div className="bg-orange-100 text-sm text-orange-500 w-fit px-3 py-1 rounded-full">
            Received
          </div>
        }
      />
      <SectionItem
        title={'Request status'}
        value={
          <div className="flex items-center space-x-3">
            <button className="bg-riverBlue rounded-lg text-white flex items-center px-5 py-2 space-x-3 flex space-x-2 ">
              <span className="inline-block text-sm">Upload</span>
              <span className="inline-block">
                <svg
                  fill="none"
                  className="w-5 h-5"
                  viewBox="0 0 20 20"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g
                    stroke="#fff"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeMiterlimit="10"
                    strokeWidth="1.5"
                  >
                    <path d="m7.7666 10.2676 2.13334-2.13335 2.13336 2.13335" />
                    <path d="m9.8999 16.666v-8.47498" />
                    <path d="m16.6663 9.84961c0-3.68333-2.5-6.66667-6.66663-6.66667-4.16666 0-6.66666 2.98334-6.66666 6.66667" />
                  </g>
                </svg>
              </span>
            </button>
            <div className="text-gray-400 text-sm">Last updated 2022-01-17 15:04:56</div>
          </div>
        }
      />
    </div>
  )
}

export default RequestImagingViewInfo
