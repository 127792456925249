import React, { ReactNode } from 'react'

interface Props {
  title: string
  value?: string | ReactNode
  inputType?: string
}

const SectionItem = (props: Props) => {
  return (
    <div className="py-3 grid grid-cols-4">
      <div className="text-sm font-light text-gray-400 col-span-1 flex items-center">
        {props.title}
      </div>
      <div className="col-span-3">
        {props.value && React.isValidElement(props.value) ? (
          props.value
        ) : (
          <>
            {props.inputType === 'input' ||
              (!props.inputType && (
                <div className="flex-1 w-full">
                  <input
                    type="text"
                    className={
                      'rounded-lg border border-gray-200 text-sm outline-none py-2 px-4 w-2/5'
                    }
                    defaultValue={props.value as string}
                  />
                </div>
              ))}
            {props.inputType === 'textarea' && (
              <div className="flex-1 w-full">
                <textarea
                  rows={4}
                  defaultValue={''}
                  className="border border-gray-200 rounded-lg py-2 px-4 w-2/5  outline-none"
                />
              </div>
            )}
          </>
        )}
      </div>
    </div>
  )
}

export default SectionItem
