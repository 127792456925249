import React from 'react'
import Avatar from '../../assets/images/avatar.png'
import DeleteModal from '../modals/delete-modal'

interface Props {
  subscribed?: boolean
}

const UserCard = (props: Props) => {
  const [open, setOpen] = React.useState<boolean>(false)

  return (
    <>
      {open && <DeleteModal setOpen={setOpen} />}
      <div className="bg-white px-8 py-6 rounded-lg flex items-center">
        <div className="flex-1 flex items-center space-x-6">
          <div className="w-20 h-20 overflow-hidden rounded-lg">
            <img
              className="w-full h-full object-cover object-center"
              src={Avatar}
              alt="avatar image"
            />
          </div>
          <div className="space-y-1">
            <div className="flex items-center space-x-3">
              <h4 className="font-medium text-lg">Tifanny Bemuah</h4>
              {props.subscribed && (
                <div className="bg-riverStatsLightGreen text-green-500 py-2 px-4 rounded-full text-xs">
                  Subscribed
                </div>
              )}
            </div>

            <p className="text-sm text-gray-500 font-light">tifanny.done@login.com</p>
          </div>
        </div>
        <button
          onClick={() => setOpen(!open)}
          className="flex items-center bg-red-50 text-red-600 py-3 px-4 rounded-lg space-x-3 font-light"
        >
          <span className="inline-block">
            <svg
              className="h-5 w-5 stroke-current fill-current"
              viewBox="0 0 16 16"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M8.00016 1.3335C6.2535 1.3335 4.8335 2.7535 4.8335 4.50016C4.8335 6.2135 6.1735 7.60016 7.92016 7.66016C7.9735 7.6535 8.02683 7.6535 8.06683 7.66016C8.08016 7.66016 8.08683 7.66016 8.10016 7.66016C8.10683 7.66016 8.10683 7.66016 8.1135 7.66016C9.82016 7.60016 11.1602 6.2135 11.1668 4.50016C11.1668 2.7535 9.74683 1.3335 8.00016 1.3335Z" />
              <path
                opacity="0.4"
                d="M11.3866 9.43342C9.52664 8.19342 6.49331 8.19342 4.61997 9.43342C3.77331 10.0001 3.30664 10.7668 3.30664 11.5868C3.30664 12.4068 3.77331 13.1668 4.61331 13.7268C5.54664 14.3534 6.77331 14.6668 7.99997 14.6668C9.22664 14.6668 10.4533 14.3534 11.3866 13.7268C12.2266 13.1601 12.6933 12.4001 12.6933 11.5734C12.6866 10.7534 12.2266 9.99342 11.3866 9.43342Z"
              />
              <path d="M8.70652 11.5868L9.29319 11.0001C9.48652 10.8068 9.48652 10.4868 9.29319 10.2934C9.09986 10.1001 8.77986 10.1001 8.58652 10.2934L7.99986 10.8801L7.41319 10.2934C7.21986 10.1001 6.89986 10.1001 6.70652 10.2934C6.51319 10.4868 6.51319 10.8068 6.70652 11.0001L7.29319 11.5868L6.70652 12.1734C6.51319 12.3668 6.51319 12.6868 6.70652 12.8801C6.80652 12.9801 6.93319 13.0268 7.05986 13.0268C7.18652 13.0268 7.31319 12.9801 7.41319 12.8801L7.99986 12.2934L8.58652 12.8801C8.68652 12.9801 8.81319 13.0268 8.93986 13.0268C9.06652 13.0268 9.19319 12.9801 9.29319 12.8801C9.48652 12.6868 9.48652 12.3668 9.29319 12.1734L8.70652 11.5868Z" />
            </svg>
          </span>
          <span className="inline-block text-sm">Delete</span>
        </button>
      </div>
    </>
  )
}

export default UserCard
